/*
 *  全局定义信息
 */

const errorPageRouteName = 'Error' //错误页面名称定义
const returnPageRouteName = 'Return'
const passGuardRouteList = [errorPageRouteName, returnPageRouteName] // 不进入路由守卫的name

export default {
  errorPageRouteName: errorPageRouteName,
  returnPageRouteName: returnPageRouteName,
  passGuardRouteList: passGuardRouteList,
  urlTokenName: 'token', //URL传递的token名称
  cacheToken: '',
}
