const utils = {
    isMobile() {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    },
    isLandscape() {
        return window.matchMedia("(orientation: landscape)").matches;
    }
}
export default utils;