import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import utils from './utils'
import config from './config'
import {Icon, Button, Cell, CellGroup, Field, Image as VanImage, RadioGroup, Radio, CountDown} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {

    // console.log("进入路由守卫", from, to)
    // console.log("进入路由History", history.state)
    // from: 当前导航正要离开的路由
    // to: 即将要进入的目标路由对象

    // 如果在免登录页面则直接放行
    if (config.passGuardRouteList.includes(to.name)) {
        // 在免登录名单，直接进入
        next()
        return false
    }
    //获取不到参数
    let token = to.params[config.urlTokenName];// || JSON.parse(localStorage.getItem('token'))
    if (!token && localStorage && localStorage.getItem) {
        token = JSON.parse(localStorage.getItem('suc-token'));
    }
    if (token) {
        //放置token信息
        config.cacheToken = token
        if (localStorage && localStorage.setItem) {
            localStorage.setItem('suc-token', JSON.stringify(token));
        }
    }

    if (!config.cacheToken) {
        next({
            name: config.errorPageRouteName,
            state: {errInfo: 'Payment is abnormal, please order again！'},
        })
        return false
    }
    next()
})

const app = createApp(App)
    .use(router)
    .use(Icon)
    .use(Button)
    .use(Cell)
    .use(CellGroup)
    .use(VanImage)
    .use(Radio)
    .use(RadioGroup)
    .use(CountDown)
    .use(Field)

// 3. 挂载到vue实例上
app.config.globalProperties.$utils = utils;
app.mount('#app')